<template>
  <v-main>
    <transition
      mode="out-in"
      @before-enter="routeBeforeEnter"
      @enter="routeEnter"
      @leave="routeLeave"
    >
      <router-view class="px-6" style="  min-width: 100% !important;margin: 0px !important;" />
    </transition>
    <dashboard-core-footer />
  </v-main>
</template>

<script>
import gsap from 'gsap'

export default {
  name: "DashboardCoreView",
  
  components: {
    DashboardCoreFooter: () => import("./Footer"),
  },

  methods: {
      routeBeforeEnter(el) {
        gsap.from(el, {
          opacity: 0,
          x: '200px'
        })
      },
      routeEnter(el) {
        gsap.to(el, {
          duration: 0.75,
          x: 0,
          opacity: 1,
        })
      },
      routeLeave(el) {
        gsap.to(el, {
          duration: 0.75,
          x: '-200px',
          opacity: 0,
        })
      },
    }
};
</script>
<style scoped>
</style>
